<template>



    <section id="app" class="section">
    <div>
        <v-card>
            <v-card-text>
    
        <div>
        <v-toolbar-title>{{form.formName}}</v-toolbar-title>
    <br>
    
                    <div class="field">
                        <label class="label">Группа</label>
                        <div class="control">
                            <input class="input elevation-2" type="text" v-model="form.group" />
                        </div>
                    </div>
    
                    <br>
        
        <v-btn
                  color="primary"
                  dark
                  small
                  @click.prevent="downloadStudentWorksByGroup"
                >
                  Скачать
        </v-btn>
    </div>
    </v-card-text>
    </v-card>
    </div>        
    
    <br>
    
    <div>
        <v-card>
            <v-card-text>
    
        <div>
        <v-toolbar-title>{{form1.formName}}</v-toolbar-title>
        <v-btn
                  color="primary"
                  dark
                  small
                  @click.prevent="downloadTasksWithSolveCount"
                >
                  Скачать
        </v-btn>
    </div>
    </v-card-text>
    </v-card>
    </div>
    <br>
    
    <div>
        <v-card>
            <v-card-text>
    
        <div>
        <v-toolbar-title>{{form2.formName}}</v-toolbar-title>
        <v-btn
                  color="primary"
                  dark
                  small
                  @click.prevent="downloadFirstAttempts"
                >
                  Скачать
        </v-btn>
    </div>
    </v-card-text>
    </v-card>
    </div>
    
    
    </section>
    
    </template>
    
    <script>

    import store from "@/store/index"
    import Swal from "sweetalert2";
    import axios from 'axios'
    import * as XLSX from 'xlsx';

      export default {
        data: () => ({
            form: {
                formName: "Работы по группе",
                group: ""
            },
    
            form1:{
                formName: "Статистика по заданиям",
            },

            form2:{
                formName: "Решенные с первой попытки",
            },
    
            showSubmitFeedback: false
        }),
    
    
    
    
        methods: {

            async downloadStudentWorksByGroup() {
                try {
                    const response = await axios.get(store.state.port + 'teacher/analysis?page=-1&action=student_works_by_group&group=' + this.form.group+ '&jwt=' + store.state.jwt);
                    const jsonData = response.data.student_works;

                    const worksheet = XLSX.utils.json_to_sheet(jsonData);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

                    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.triggerDownload(excelBuffer, `Работы${this.form.group}.xlsx`);
                } catch (error) {
                    Swal.fire({
                    icon: 'error',
                    title: 'Ошибка загрузки JSON',
                })
                console.error(error)
                }
            },

            async downloadTasksWithSolveCount() {
                try {
                    const response = await axios.get(store.state.port + 'teacher/analysis?page=-1&action=tasks_with_solve_count' + '&jwt=' + store.state.jwt);
                    const jsonData = response.data;

                    const worksheet = XLSX.utils.json_to_sheet(jsonData);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

                    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.triggerDownload(excelBuffer, `СтатистикаЗадания.xlsx`);
                } catch (error) {
                    Swal.fire({
                    icon: 'error',
                    title: 'Ошибка загрузки JSON',
                })
                console.error(error)
                }
            },

            async downloadFirstAttempts() {
                try {
                    const response = await axios.get(store.state.port + 'teacher/analysis?page=-1&action=solved_first_attempt' + '&jwt=' + store.state.jwt);
                    const jsonData = response.data;

                    const worksheet = XLSX.utils.json_to_sheet(jsonData);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

                    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    this.triggerDownload(excelBuffer, `ПервыеПопытки.xlsx`);
                } catch (error) {
                    Swal.fire({
                    icon: 'error',
                    title: 'Ошибка загрузки JSON',
                })
                console.error(error)
                }
            },

            triggerDownload(excelBuffer, filename) {
                const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            },
        }
        }
    </script>